import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, TextField, Button, Rating, CircularProgress, IconButton } from '@mui/material';
import ModuleHeader from '../../../../components/moduleHeader/ModuleHeader';
import { useGetHotelByIdQuery, useUpdateHotelMutation } from '../../../../apis/hotelApis/hotelApi';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../../../app/store/reducers/currencySlice';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const EditHotel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currencies = useSelector(getAllCurrencies);
  const { data: hotel, isLoading, isError } = useGetHotelByIdQuery(id);
  const [updateHotel, { isLoading: isUpdating, error: updateError }] = useUpdateHotelMutation();

  const [formData, setFormData] = useState({
    hotelName: '',
    description: '',
    rating: 0,
    location: '',
    images: [],
    roomsData: [],
  });

  useEffect(() => {
    if (hotel) {
      setFormData({
        hotelName: hotel.hotelName,
        description: hotel.description,
        rating: hotel.rating || 0,
        location: hotel.location,
        images: hotel.images || [],
        roomsData: hotel.rooms || [],
      });
    }
  }, [hotel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = [...formData.roomsData];
    updatedRooms[index][field] = value;
    setFormData({ ...formData, roomsData: updatedRooms });
  };

  const handleAddRoom = () => {
    const newRoom = {
      type: '',
      price: { base_price: 0, weekend_price: 0 },
      capacityAdult: 0,
      capacityChild: 0,
    };
    setFormData({ ...formData, roomsData: [...formData.roomsData, newRoom] });
  };

  const handleDeleteRoom = (index) => {
    const updatedRooms = formData.roomsData.filter((_, i) => i !== index);
    setFormData({ ...formData, roomsData: updatedRooms });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await updateHotel({ hotelId: id, body: formData });

    if (response?.data) {
      navigate(`/hotel/${id}`);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (isError) return <Typography color="error">Error fetching hotel details</Typography>;

  return (
    <>
      {hotel && (
        <>
          <ModuleHeader
            headerTitle={`Edit: ${hotel.hotelName}`}
            headerImg={hotel.images && hotel.images.length > 0 ? hotel.images[0] : null}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Edit Hotel</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Hotel Name"
                  name="hotelName"
                  value={formData.hotelName}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
                <Typography variant="h6" mt={2}>
                  Rooms
                </Typography>
                {formData.roomsData.map((room, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Room Type"
                        value={room.type}
                        onChange={(e) => handleRoomChange(index, 'type', e.target.value)}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Base Price"
                        type="number"
                        value={room.price?.base_price || 0}
                        onChange={(e) => handleRoomChange(index, 'price', {
                          ...room.price,
                          base_price: Number(e.target.value),
                        })}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Weekend Price"
                        type="number"
                        value={room.price?.weekend_price || 0}
                        onChange={(e) => handleRoomChange(index, 'price', {
                          ...room.price,
                          weekend_price: Number(e.target.value),
                        })}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Adult Capacity"
                        type="number"
                        value={room.capacityAdult}
                        onChange={(e) => handleRoomChange(index, 'capacityAdult', Number(e.target.value))}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Child Capacity"
                        type="number"
                        value={room.capacityChild}
                        onChange={(e) => handleRoomChange(index, 'capacityChild', Number(e.target.value))}
                        required
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IconButton onClick={() => handleDeleteRoom(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddRoom}
                  variant="contained"
                  color="secondary"
                  style={{ marginTop: '10px' }}
                >
                  Add Room
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isUpdating}
                  style={{ marginTop: '10px' }}
                >
                  {isUpdating ? 'Updating...' : 'Update Hotel'}
                </Button>
              </form>
              {updateError && <Typography color="error">Error updating hotel.</Typography>}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" fontWeight={700}>
                Current Rating:
              </Typography>
              <Rating value={hotel.rating} readOnly precision={0.5} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default EditHotel;
