import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Rating, Typography, Card, CardMedia, CardContent, CardActions, Button, Box } from '@mui/material';
import ModuleHeader from '../../../../components/moduleHeader/ModuleHeader';
import { useGetHotelByIdQuery } from '../../../../apis/hotelApis/hotelApi';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../../../app/store/reducers/currencySlice';

const ViewHotel = () => {
  const { id } = useParams();
  const currencies = useSelector(getAllCurrencies);
  const { data: hotel, isLoading, isError } = useGetHotelByIdQuery(id);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError || !hotel) return <Typography>Error loading hotel details.</Typography>;

  const currencyCode = currencies[hotel.country]?.[0]?.code || '';

  return (
    <>
      <ModuleHeader
        headerTitle={hotel.hotelName}
        headerImg={hotel.images?.[0] || null}
      />
      <Grid container spacing={4} style={{ marginTop: '16px' }}>
        {/* Hotel Information */}
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            About the Hotel
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {hotel.description || 'No description available.'}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Address
          </Typography>
          <Typography color="text.secondary">{hotel.address || 'N/A'}</Typography>
          <Typography color="text.secondary">{hotel.zipCode || ''}</Typography>
          <Typography variant="h5" gutterBottom>
            Contact Information
          </Typography>
          <Typography color="text.secondary">Phone: {hotel.phone || 'N/A'}</Typography>
          <Typography color="text.secondary">Website: {hotel.website || 'N/A'}</Typography>
        </Grid>

        {/* Hotel Highlights */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Rating:
              </Typography>
              <Rating value={hotel.rating || 0} readOnly precision={0.5} />
              <Typography variant="h6" gutterBottom>
                Location:
              </Typography>
              <Typography>{hotel.location}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Rooms Section */}
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Available Rooms
          </Typography>
          <Grid container spacing={3}>
            {hotel.rooms.map((room) => (
              <Grid item xs={12} sm={6} md={4} key={room._id}>
                <Card style={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={room.images?.[0] || 'https://via.placeholder.com/200'}
                    alt={room.type}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {room.type}
                    </Typography>
                    <Typography color="text.secondary">
                      Capacity: {room.capacityAdult} Adult{room.capacityAdult > 1 ? 's' : ''}, {room.capacityChild} Child{room.capacityChild > 1 ? 'ren' : ''}, {room.capacityInfant} Infant{room.capacityInfant > 1 ? 's' : ''}
                    </Typography>
                    <Typography color="text.secondary">
                      Facilities: {room.facility.length > 0 ? room.facility.join(', ') : 'No facilities listed'}
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body1">
                        Base Price: {currencyCode} {room.price.base_price}
                      </Typography>
                      <Typography variant="body1">
                        Weekend Price: {currencyCode} {room.price.weekend_price}
                      </Typography>
                      <Typography variant="body1">
                        Breakfast Price: {currencyCode} {room.price.breakfast_price}
                      </Typography>
                      <Typography variant="body1">
                        Lunch Price: {currencyCode} {room.price.lunch_price}
                      </Typography>
                      <Typography variant="body1">
                        Dinner Price: {currencyCode} {room.price.dinner_price}
                      </Typography>
                      {room.price.festive_seasons.length > 0 && (
                        <Box mt={1}>
                          <Typography variant="body1" fontWeight="bold">
                            Festive Prices:
                          </Typography>
                          {room.price.festive_seasons.map((season, index) => (
                            <Typography key={index} variant="body2" color="text.secondary">
                              {new Date(season.start).toISOString().split('T')[0]} - {new Date(season.end).toISOString().split('T')[0]}: {currencyCode} {season.festive_price}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewHotel;
