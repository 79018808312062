import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useCreateVisaMutation } from '../../../../apis/locationsApi/locationsApi';
import MenuItem from '@mui/material/MenuItem';
import { uploadImages } from '../../../../helpers/imageUploadHelper';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const CreateLocations = () => {
  const formRef = React.useRef();
  const [createVisa] = useCreateVisaMutation();

  const country = [
    {
      value: 'United Arab Emirates',
      label: 'United Arab Emirates',
      location: 'Dubai',
    },
    {
      value: 'Kingdom of Thailand',
      label: 'Kingdom of Thailand',
      location: 'Thailand',
    },
    {
      value: 'Republic of Singapore',
      label: 'Republic of Singapore',
      location: 'Singapore',
    },
    {
      value: 'Indonesia',
      label: 'Indonesia',
      location: 'Bali',
    },
    {
      value: 'Vietnam',
      label: 'Vietnam',
      location: 'Vietnam',
    },
    { value: 'Azerbaijan', label: 'Azerbaijan', location: 'Baku' },
    { value: 'India', label: 'India', location: 'India' },
  ];

  const currencyMapping = {
    'United Arab Emirates': [{ code: 'AED', name: 'Dirham' }],
    'Kingdom of Thailand': [{ code: 'THB', name: 'Baht' }],
    'Republic of Singapore': [{ code: 'SGD', name: 'Dollar' }],
    Indonesia: [{ code: 'IDR', name: 'Rupiah' }],
    Vietnam: [{ code: 'USD', name: 'United States Dollar' }],
    Azerbaijan: [{ code: 'USD', name: 'United States Dollar' }],
    India: [{ code: 'INR', name: 'Rupee' }],
  };

  const INITIAL_VALUES = {
    country: '',
    visaType: 'Tourist Visa',
    note: '',
    checklist: '',
    adult_price: 0.0,
    child_price: 0.0,
    infant_price: 0.0,
  };

  return (
    <Paper elevation={6} sx={{ padding: '1rem' }}>
      <p className="text-3xl font-bold">Create Visa</p>
      <Divider />
      <br />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, actions) => {
          const checklist = values.checklist
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');
          const note = values.note
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');
          const body = {
            country: values.country,
            note,
            checklist,
            visaType: values.visaType,
            price: {
              adult_price: parseFloat(values.adult_price).toFixed(2),
              child_price: parseFloat(values.child_price).toFixed(2),
              infant_price: parseFloat(values.infant_price).toFixed(),
            },
          };
          createVisa(body)
            .unwrap()
            .then((res) => {
              toast.success(
                `Visa for ${values.country} Created Successfully`,
                TOAST_CONFIG,
              );
              actions.resetForm();
            })
            .catch((err) => {
              if (
                err.status === 400 &&
                err.data.message === 'Visa already exists'
              ) {
                toast.error(`Visa already exists`, TOAST_CONFIG);
              } else {
                toast.error(`Error Occurred`, TOAST_CONFIG);
              }
              console.error(err);
            });
        }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  select
                  label="Country Name"
                  name="country"
                  type="text"
                  fullWidth
                  className="mb-3"
                  onChange={(e) => {
                    setFieldValue('country', e.target.value);
                  }}
                  required
                >
                  {country.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  as={TextField}
                  label="Note (Comma Separated)"
                  name="note"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label="Checklist (Comma Separated)"
                  name="checklist"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
            </Grid>
            <p className="my-4 text-xl font-bold">Prices</p>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  label={`Adult Price ${currencyMapping[values.country]?.[0].code || ''}`}
                  name="adult_price"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  label={`Child Price ${currencyMapping[values.country]?.[0].code || ''}`}
                  name="child_price"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  label={`Infant Price ${currencyMapping[values.country]?.[0].code || ''}`}
                  name="infant_price"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginY: '0.7rem' }} />
            <Button type="submit" variant="contained" disableElevation>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default CreateLocations;
