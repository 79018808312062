import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

const HotelTimeline = ({ hotel }) => {
  return (
    <Box border={1} borderColor="#ddd" padding={3} marginY={3} borderRadius={2} boxShadow={3}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        {hotel.hotel_name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Check-in:</strong> {new Date(hotel.check_in).toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Check-out:</strong> {new Date(hotel.check_out).toLocaleDateString()}
          </Typography>
        </Grid>
        
      </Grid>

      <Typography variant="h5" gutterBottom marginTop={4}>
        Selected Rooms
      </Typography>
      {hotel.selectedRooms.map((room, index) => (
        <Card key={index} variant="outlined" sx={{ marginY: 2, boxShadow: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                image={room.room_id.images[0]}
                alt={room.room_id.type}
                sx={{ height: 200, objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CardContent>
                <Typography variant="h6" color="textPrimary">
                  {room.room_name || "Unknown Room"}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <strong>Type:</strong> {room.room_id.type}
                </Typography>
                {/* <Typography variant="body1" color="textSecondary">
                  <strong>Room Price (Per Night):</strong> ₹{room.room_price.toLocaleString()}
                </Typography> */}
                
                <Typography variant="body1" color="textSecondary">
                  <strong>Capacity:</strong> {room.room_id.capacityAdult} Adults,{" "}
                  {room.room_id.capacityChild} Children, {room.room_id.capacityInfant} Infants
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <strong>Facilities:</strong> {room.room_id.facility.join(", ")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <strong>Complementary Breakfast:</strong>{" "}
                  {room.room_id.complementary_breakfast ? "Yes" : "No"}
                </Typography>

                <Typography variant="h6" marginTop={2} gutterBottom>
                  Meal Options
                </Typography>
                {room.meals
                  .filter((meal) => meal.opted)
                  .map((meal, idx) => (
                    <Typography key={idx} variant="body2" color="textSecondary">
                      {meal.type.charAt(0).toUpperCase() + meal.type.slice(1)}: Included
                    </Typography>
                  ))}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Box>
  );
};

export default HotelTimeline;
