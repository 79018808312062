import { useState } from 'react';
import { Plus, Trash2, Check } from 'lucide-react';
import { Button } from '../../../components/ui/Button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../../components/ui/card';
import { Input } from '../../../components/ui/Input';
import { Label } from '../../../components/ui/Label';
import { useGetAllHotelsQuery } from '../../../apis/hotelApis/hotelApi';
import Select, { components } from 'react-select';
import { Box, Typography } from '@mui/material';
import { checkBookingDatesComprehensive } from '../../../utils/hotelPrice';
import { Checkbox } from '../../../components/ui/checkbox';
import { useParams } from 'react-router-dom';
import {
  useGetItineraryByIdQuery,
  useAddHotelToItineraryMutation,
} from '../../../apis/itineraryApis/itineraryApi';
import { toast } from 'react-toastify';
import { getAllCurrencies } from '../../../app/store/reducers/currencySlice';
import { useSelector } from 'react-redux';

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const SelectRoomCard = ({ hotel, roomType, addRoom }) => {
  console.log('hotel', hotel);
  console.log('roomType', roomType);
  const { hasWeekend, hasFestiveDate } = checkBookingDatesComprehensive(
    hotel.checkIn,
    hotel.checkOut,
    roomType.price?.festive_seasons || [],
  );
  const isDateInRange = (date, start, end) => {
    const target = new Date(date);
    return target >= new Date(start) && target <= new Date(end);
  };

  let festivePrice = null;
  if (hasFestiveDate) {
    const festiveSeason = roomType.price?.festive_seasons.find((season) => {
      return (
        isDateInRange(hotel.checkIn, season.start, season.end) ||
        isDateInRange(hotel.checkOut, season.start, season.end) ||
        (new Date(hotel.checkIn) <= new Date(season.start) &&
          new Date(hotel.checkOut) >= new Date(season.end))
      );
    });

    festivePrice = festiveSeason?.festive_price || null;
  }

  let outputPrice = festivePrice
    ? festivePrice
    : hasWeekend
      ? roomType.price.weekend_price
      : roomType.price.base_price;
  console.log(outputPrice);

  const [meals, setMeals] = useState({
    breakfast: {
      price: roomType.price.breakfast_price,
      opted: false,
    },
    lunch: {
      price: roomType.price.lunch_price,
      opted: false,
    },
    dinner: {
      price: roomType.price.dinner_price,
      opted: false,
    },
  });

  return (
    <Card key={roomType._id} className="overflow-hidden">
      <img
        src={roomType.images[0]}
        alt={roomType.type}
        className="object-cover w-full h-48"
      />
      <CardContent className="p-4">
        <h3 className="mb-2 text-lg font-semibold">{roomType.type}</h3>
        <p className="mb-2 text-sm text-gray-600">{outputPrice}/night</p>
        <p className="mb-2 text-sm">
          {roomType.description?.substring(0, 100)}
          {roomType.description?.length > 100 && '...'}
        </p>
        <div className="grid grid-cols-3 gap-2 mb-4 text-sm">
          <p>Max Adult: {roomType.capacityAdult}</p>
          <p>Max Child: {roomType.capacityChild}</p>
          <p>Max Infant: {roomType.capacityInfant}</p>
        </div>
        <div className="flex flex-wrap gap-4 mb-4">
          {Object.keys(meals).map((mealKey) => (
            <div key={mealKey} className="flex items-center space-x-2">
              <Checkbox
                id={`${mealKey}-${roomType._id}`}
                checked={meals[mealKey].opted}
                defaultChecked={
                  mealKey === 'breakfast' && roomType.complementary_breakfast
                }
                onCheckedChange={(checked) =>
                  setMeals((prevMeals) => ({
                    ...prevMeals,
                    [mealKey]: { ...prevMeals[mealKey], opted: checked },
                  }))
                }
              />
              <label
                htmlFor={`${mealKey}-${roomType._id}`}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {mealKey.charAt(0).toUpperCase() + mealKey.slice(1)}
              </label>
            </div>
          ))}
        </div>
        <Button
          variant="default"
          className="w-full"
          onClick={() => addRoom(hotel._id, roomType, outputPrice, meals)}
        >
          <Plus className="w-4 h-4 mr-2" /> Add Room
        </Button>
      </CardContent>
    </Card>
  );
};

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Box display={'flex'} alignItems={'center'} gap={2} textAlign={'left'}>
        <img
          src={children?.option?.images[0] || ''}
          alt="Hotel"
          width={150}
          height={150}
        />
        <Typography>{children?.option[children?.fieldName]}</Typography>
      </Box>
    </components.Option>
  );
};

const filterOptions = (candidate, input) => {
  let searchItem = candidate.label.option['hotelName'].toLowerCase();
  if (input) {
    return searchItem.includes(input.toLowerCase());
  }
  return true;
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <Box display={'flex'} alignItems={'center'} gap={2} textAlign={'left'}>
      <Typography sx={{ whiteSpace: 'normal' }}>
        {children?.option[children?.fieldName]}
      </Typography>
    </Box>
  </components.SingleValue>
);

export default function BookItineraryHotel() {
  const currencies = useSelector(getAllCurrencies);
  console.log(currencies);
  const { id } = useParams();
  const { data: hotelData } = useGetAllHotelsQuery({ keyword: '' });
  console.log(hotelData);
  const {
    data: itineraryData,
    isLoading,
    isError,
  } = useGetItineraryByIdQuery(id);
  console.log(itineraryData);
  const itineraryCountry = itineraryData?.country;
  const matchedCurrency = itineraryCountry
    ? currencies[itineraryCountry]?.[0]
    : null;

  const [addHotelToItinerary] = useAddHotelToItineraryMutation();

  const [hotels, setHotels] = useState([]);
  const [newHotel, setNewHotel] = useState({
    name: '',
    checkIn: '',
    checkOut: '',
  });

  const addHotel = () => {
    if (newHotel?.hotelName && newHotel.checkIn && newHotel.checkOut) {
      setHotels([...hotels, { ...newHotel, selectedRooms: [] }]);
      setNewHotel({ name: '', checkIn: '', checkOut: '' });
    }
  };

  const removeHotel = (id) => {
    setHotels(hotels.filter((hotel) => hotel._id !== id));
  };

  const addRoom = (hotelId, roomType, displayPrice, meals) => {
    setHotels(
      hotels.map((hotel) => {
        if (hotel._id === hotelId) {
          if (roomType) {
            const existingRoom = hotel.selectedRooms.find(
              (r) => r.type === roomType.type,
            );
            if (existingRoom) {
              return {
                ...hotel,
                selectedRooms: hotel.selectedRooms.map((r) =>
                  r._id === existingRoom._id
                    ? { ...r, quantity: r.quantity + 1, meals }
                    : r,
                ),
              };
            } else {
              return {
                ...hotel,
                selectedRooms: [
                  ...hotel.selectedRooms,
                  {
                    ...roomType,
                    quantity: 1,
                    displayPrice,
                    meals,
                  },
                ],
              };
            }
          }
        }
        return hotel;
      }),
    );
  };

  const removeRoom = (hotelId, roomId) => {
    setHotels(
      hotels.map((hotel) => {
        if (hotel._id === hotelId) {
          const updatedRooms = hotel.selectedRooms
            .map((room) =>
              room._id === roomId && room.quantity > 0
                ? { ...room, quantity: room.quantity - 1 }
                : room,
            )
            .filter((room) => room.quantity > 0);
          return { ...hotel, selectedRooms: updatedRooms };
        }
        return hotel;
      }),
    );
  };

  const calculateTotalPrice = (hotel) => {
    return hotel.selectedRooms.reduce((total, room) => {
      const mealsTotal = Object.values(room.meals).reduce((sum, meal) => {
        return meal.opted ? sum + meal.price : sum;
      }, 0);
      const roomTotal = (room.displayPrice + mealsTotal) * room.quantity;

      return total + roomTotal;
    }, 0);
  };

  const updateHotelHandler = async () => {
    try {
      if (!hotels || !Array.isArray(hotels)) {
        throw new Error('Invalid hotels data');
      }
      let total_hotel_amount = 0.0;
      const resultHotel = hotels.map((hotel) => {
        const checkInDate = new Date(hotel.checkIn);
        const checkOutDate = new Date(hotel.checkOut);

        // Calculate the difference in time (in milliseconds)
        const durationInMilliseconds = checkOutDate - checkInDate;

        // Convert milliseconds to days
        const durationOfStay = durationInMilliseconds / (1000 * 60 * 60 * 24);
        let total_hotel_price = calculateTotalPrice(hotel) * durationOfStay;

        const rooms = (hotel.selectedRooms || []).map((room) => {
          const total_room_price = room.displayPrice || 0;

          const meals = Object.keys(room.meals || {})
            // .filter((meal) => room.meals[meal].opted)
            .map((meal) => ({
              type: meal,
              opted: room.meals[meal]?.opted || false,
              price: room.meals[meal]?.price || 0,
            }));

          return {
            room_id: room._id,
            qty: room.quantity || 1,
            room_price: total_room_price,
            meals,
          };
        });
        total_hotel_amount += total_hotel_price;
        return {
          total_hotel_amount,
          hotels: {
            hotel_id: hotel._id,
            check_in: hotel.checkIn,
            check_out: hotel.checkOut,
            selectedRooms: rooms,
            total_hotel_price,
            // ...hotel,
          },
        };
      });

      await addHotelToItinerary({
        itineraryId: itineraryData._id,
        body: resultHotel[0],
      })
        .unwrap()
        .then((res) => {
          toast.success(`Booked hotel`, TOAST_CONFIG);
        })
        .catch((err) => {
          toast.error(`Error Occured`, TOAST_CONFIG);
          console.error(err);
        });
    } catch (error) {
      console.error('Error updating hotels:', error);
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="container p-4 mx-auto">
          <h1 className="mb-6 text-3xl font-bold">Book Hotel</h1>
          <p className="mb-4 text-lg">Hotels added: {hotels.length}</p>

          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Add New Hotel</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="hotelName">Hotel Name</Label>
                    {hotelData?.data?.length > 0 && (
                      <Select
                        className={'custom-select'}
                        name={'hotel'}
                        getOptionValue={(options) => options['_id']}
                        getOptionLabel={(option) => ({
                          option,
                          fieldName: 'hotelName',
                        })}
                        onChange={(e) => {
                          setNewHotel({ ...newHotel, ...e });
                        }}
                        placeholder={'Select Hotel'}
                        options={hotelData.data.filter(
                          (hotel) => hotel.location === itineraryData.location,
                        )}
                        isMulti={false}
                        components={{
                          Option: CustomOption,
                          SingleValue: SingleValue,
                        }}
                        filterOption={filterOptions}
                      />
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="checkIn">Check-in Date</Label>
                    <Input
                      id="checkIn"
                      type="date"
                      value={newHotel.checkIn}
                      onChange={(e) =>
                        setNewHotel({ ...newHotel, checkIn: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="checkOut">Check-out Date</Label>
                    <Input
                      id="checkOut"
                      type="date"
                      value={newHotel.checkOut}
                      onChange={(e) =>
                        setNewHotel({ ...newHotel, checkOut: e.target.value })
                      }
                    />
                  </div>
                </div>
                <Button onClick={addHotel}>
                  <Plus className="w-4 h-4 mr-2" /> Add Hotel
                </Button>
              </div>
            </CardContent>
          </Card>

          {hotels.map((hotel) => (
            <Card key={hotel.id} className="mb-4">
              <CardHeader>
                <CardTitle className="flex items-center justify-between">
                  <span>{hotel.hotelName}</span>
                  <Button
                    variant="destructive"
                    size="icon"
                    onClick={() => removeHotel(hotel._id)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <Label>Check-in Date</Label>
                      <div>{hotel.checkIn}</div>
                    </div>
                    <div>
                      <Label>Check-out Date</Label>
                      <div>{hotel.checkOut}</div>
                    </div>
                  </div>

                  <div>
                    <Label className="mb-2 text-xl font-semibold">
                      Select Room Types
                    </Label>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {hotel.rooms?.map((roomType) => (
                        <SelectRoomCard
                          key={roomType._id}
                          hotel={hotel}
                          roomType={roomType}
                          addRoom={addRoom}
                        />
                      ))}
                    </div>
                  </div>

                  {hotel.selectedRooms.length > 0 && (
                    <div>
                      <h3 className="mb-2 text-xl font-semibold">
                        Selected Rooms:
                      </h3>
                      <ul className="space-y-4">
                        {hotel.selectedRooms.map((room) => (
                          <li key={room._id} className="p-4 border rounded-lg">
                            <div className="flex items-center justify-between mb-2">
                              <span className="text-lg font-semibold">
                                {room.type} -{' '}
                                {matchedCurrency
                                  ? ` (${matchedCurrency.code})`
                                  : ''}
                                {room.displayPrice}/night
                              </span>
                              <div className="flex items-center space-x-2">
                                <span className="font-semibold">
                                  x {room?.quantity || 0}
                                </span>
                                <div>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() =>
                                      addRoom(
                                        hotel._id,
                                        room,
                                        room.displayPrice,
                                        room.meals,
                                      )
                                    }
                                  >
                                    +
                                  </Button>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() =>
                                      removeRoom(hotel._id, room._id)
                                    }
                                    className="ml-2"
                                  >
                                    -
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="text-sm">
                              Meals:{' '}
                              {Object.entries(room.meals)
                                .filter(([, value]) => value.opted)
                                .map(
                                  ([key]) =>
                                    key.charAt(0).toUpperCase() + key.slice(1),
                                )
                                .join(', ') || 'None'}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div className="font-semibold text-right">
                    Total: {matchedCurrency ? ` (${matchedCurrency.code})` : ''}
                    {calculateTotalPrice(hotel)}/night
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}

          {hotels.length > 0 && (
            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Itinerary Summary</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="space-y-4">
                  {hotels.map((hotel) => (
                    <li key={hotel.id}>
                      <div className="flex items-center justify-between font-semibold">
                        <span>
                          {hotel.name} ({hotel.checkIn} to {hotel.checkOut})
                        </span>
                        <span>
                          {matchedCurrency ? ` (${matchedCurrency.code})` : ''}
                          {calculateTotalPrice(hotel)}/night
                        </span>
                      </div>
                      <ul className="mt-2 space-y-1">
                        {hotel.selectedRooms.map((room) => (
                          <li
                            key={room._id}
                            className="flex items-center justify-between text-sm"
                          >
                            <span>
                              {room.type} x {room.quantity}
                            </span>
                            <span>
                              {matchedCurrency
                                ? ` (${matchedCurrency.code})`
                                : ''}
                              {room.displayPrice * room.quantity}/night
                            </span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <div className="mt-6 text-lg font-bold text-right">
                  Total for all hotels:{' '}
                  {matchedCurrency ? ` (${matchedCurrency.code})` : ''}
                  {hotels.reduce(
                    (total, hotel) => total + calculateTotalPrice(hotel),
                    0,
                  )}
                  /night
                </div>
              </CardContent>
              <CardFooter>
                <Button variant="outline" onClick={updateHotelHandler}>
                  <Check className="w-4 h-4 mr-2" /> Confirm Booking
                </Button>
              </CardFooter>
            </Card>
          )}
        </div>
      )}
    </>
  );
}
