/**
 * Checks if there is a Saturday or Sunday within the date range from startDate to endDate.
 *
 * @param {string | Date} startDate - The start date of the range.
 * @param {string | Date} endDate - The end date of the range.
 * @returns {boolean} - Returns true if a Saturday or Sunday is within the range, else false.
 */
export function isWeekendWithinRange(startDate, endDate) {
  // Convert input to Date objects if they aren't already
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Iterate from start to end
  let currentDate = new Date(start);

  while (currentDate <= end) {
    // getDay() returns 0 for Sunday, 6 for Saturday
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      // 6: Saturday, 0: Sunday
      return true;
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // No Saturday or Sunday found in the range
  return false;
}

/**
 * Checks if at least one of the startDate or endDate falls within any festive season range.
 *
 * @param {string | Date} startDate - The start date of the booking.
 * @param {string | Date} endDate - The end date of the booking.
 * @param {Array} festiveSeasons - Array of festive seasons with start and end dates.
 * @returns {boolean} - Returns true if either startDate or endDate is within any festive season, else false.
 */
export function isDateWithinFestiveSeasons(startDate, endDate, festiveSeasons) {
  // Convert input dates to Date objects if they aren't already
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Helper function to check if a single date is within any festive season
  function isWithinAnyFestiveSeason(date, seasons) {
    return seasons.some((season) => {
      const festStart = new Date(season.start);
      const festEnd = new Date(season.end);
      return date >= festStart && date <= festEnd;
    });
  }

  // Check if startDate is within any festive season
  const isStartFestive = isWithinAnyFestiveSeason(start, festiveSeasons);

  // Check if endDate is within any festive season
  const isEndFestive = isWithinAnyFestiveSeason(end, festiveSeasons);

  // Return true if either startDate or endDate is festive
  return isStartFestive || isEndFestive;
}

/**
 * Checks if a booking includes a weekend and if either startDate or endDate falls within any festive season.
 *
 * @param {string | Date} startDate - The start date of the booking.
 * @param {string | Date} endDate - The end date of the booking.
 * @param {Array} festiveSeasons - Array of festive seasons with start and end dates.
 * @returns {Object} - An object containing:
 *   - hasWeekend: {boolean} indicating presence of a weekend.
 *   - hasFestiveDate: {boolean} indicating if either startDate or endDate is within a festive season.
 */
export function checkBookingDatesComprehensive(
  startDate,
  endDate,
  festiveSeasons,
) {
  // Helper function to check for weekends
  function hasWeekend(start, end) {
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        // Saturday or Sunday
        return true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return false;
  }

  // Helper function to check if either startDate or endDate is within any festive season
  function hasFestiveDate(start, end, seasons) {
    // Nested function to check a single date
    function isWithinAnyFestiveSeason(date) {
      return seasons.some((season) => {
        const festStart = new Date(season.start);
        const festEnd = new Date(season.end);
        return date >= festStart && date <= festEnd;
      });
    }

    const isStartFestive = isWithinAnyFestiveSeason(start);
    const isEndFestive = isWithinAnyFestiveSeason(end);

    return isStartFestive || isEndFestive;
  }

  // Convert inputs to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Check for weekends
  const weekend = hasWeekend(start, end);

  // Check for festive dates
  const festive = hasFestiveDate(start, end, festiveSeasons);

  return {
    hasWeekend: weekend,
    hasFestiveDate: festive,
  };
}
