// src/components/EditItinerary/reducer.js

import { getActivityPrice } from './utils';

export const initialState = {
  states: [],
  cities: [],
  days: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_STATE': {
      const stateArr =
        action.payload && action.payload.states ? action.payload.states : [];
      return { ...state, states: stateArr };
    }
    case 'CHANGE_CITY': {
      const cityArr =
        action.payload && action.payload.cities ? action.payload.cities : [];
      return { ...state, cities: cityArr };
    }
    case 'CHANGE_DAYS':
      return { ...state, days: action.payload };
    case 'ADD_ACTIVITY': {
      const { index, data } = action.payload;
      const updatedDays = [...state.days];
      updatedDays[index].push(data);
      return { ...state, days: updatedDays };
    }
    case 'REMOVE_ACTIVITY': {
      const { index } = action.payload;
      const updatedDays = [...state.days];
      updatedDays[index].pop();
      return { ...state, days: updatedDays };
    }
    case 'CHANGE_SINGLE_DAY': {
      const { id, index, idx, activityData } = action.payload;
      const activity = activityData.find((x) => x._id === id);
      const updatedDays = [...state.days];
      updatedDays[index][idx].activity = activity;
      return { ...state, days: updatedDays };
    }
    case 'CHANGE_TRANSFER': {
      const { index, idx, transportType } = action.payload;
      const updatedDays = [...state.days];
      const dayActivity = updatedDays[index][idx];
      dayActivity.transportType = transportType;
      dayActivity.AdultCost = 0;
      dayActivity.ChildCost = 0;
      dayActivity.InfantCost = 0;

      const { numberOfAdults, numberOfChildren, numberOfInfants } = dayActivity;
      const totalPrice = getActivityPrice(
        dayActivity.activity,
        transportType,
        numberOfAdults,
        numberOfChildren,
      );
      dayActivity.TotalCost = totalPrice;

      if (transportType === 'PT') {
        const totalCount = numberOfAdults + numberOfChildren + numberOfInfants;
        const perPersonCost = totalPrice / totalCount;
        dayActivity.AdultCost = perPersonCost * numberOfAdults;
        dayActivity.ChildCost = perPersonCost * numberOfChildren;
        dayActivity.InfantCost = perPersonCost * numberOfInfants;
      } else {
        dayActivity.AdultCost = getActivityPrice(
          dayActivity.activity,
          transportType,
          numberOfAdults,
          0,
        );
        dayActivity.ChildCost = getActivityPrice(
          dayActivity.activity,
          transportType,
          0,
          numberOfChildren,
        );
        dayActivity.InfantCost = 0.0;
      }

      return { ...state, days: updatedDays };
    }
    case 'UPDATE_PRICES': {
      const { adult, child, infant } = action.payload;
      const updatedDays = state.days.map((day) =>
        day.map((activity) => {
          activity.AdultCost = 0;
          activity.ChildCost = 0;
          activity.InfantCost = 0;
          activity.numberOfAdults = adult;
          activity.numberOfChildren = child;
          activity.numberOfInfants = infant;

          if (activity.activity?._id) {
            const transportType =
              activity.transportType || activity.activity.transportType;
            const totalPrice = getActivityPrice(
              activity.activity,
              transportType,
              adult,
              child,
            );
            const totalPeople = adult + child + infant;
            activity.TotalCost = totalPrice;

            if (transportType === 'PT') {
              const eachPersonCost = totalPrice / totalPeople;
              activity.AdultCost = eachPersonCost * adult;
              activity.ChildCost = eachPersonCost * child;
              activity.InfantCost = eachPersonCost * infant;
            } else {
              activity.AdultCost = getActivityPrice(
                activity.activity,
                transportType,
                adult,
                0,
              );
              activity.ChildCost = getActivityPrice(
                activity.activity,
                transportType,
                0,
                child,
              );
              activity.InfantCost = 0.0;
            }
          }

          return activity;
        }),
      );

      return { ...state, days: updatedDays };
    }
    default:
      return state;
  }
};
