import React from "react";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom"; 
import { useGetItineraryByIdQuery } from "../../../../apis/itineraryApis/itineraryApi"; 
import Itinerary from "../../../../components/itinerary/Itinerary"; 

const ViewItineraries = () => { 
  const { id } = useParams(); 
  const { data, isLoading, isError } = useGetItineraryByIdQuery(
    id,
    { refetchOnMountOrArgChange: true },
  ); 
  // console.log(data);
  if (isLoading) { 
    return <Typography>Loading...</Typography>; 
  }

  if (isError) { 
    return <Typography>Error fetching activity</Typography>; 
  } 
  return <>{data && <Itinerary data={data || []} />}</>; 
}; 

export default ViewItineraries;
