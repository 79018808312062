// src/components/EditItinerary/utils.js

export const addDaysToDate = (inputDate, numDays) => {
  const parts = inputDate.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // zero-based index
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month, day);
  date.setDate(date.getDate() + numDays);

  const result =
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0');

  return result;
};

export const calculateCheckoutDate = (startDate, nights) => {
  const checkIn = new Date(startDate);
  checkIn.setDate(checkIn.getDate() + parseInt(nights, 10));
  return checkIn.toISOString().split('T')[0];
};

export const getActivityPrice = (activity, transport, noa, noc) => {
  if (['WOT', 'PT', 'ST'].includes(transport)) {
    let cost;
    if (transport === 'WOT') {
      cost =
        activity.price[transport]['AdultCost'] * noa +
        activity.price[transport]['ChildCost'] * noc;
      return cost;
    } else if (transport === 'ST') {
      cost =
        (activity.price[transport]['AdultCost'] +
          activity.price['WOT']['AdultCost']) *
          noa +
        (activity.price[transport]['ChildCost'] +
          activity.price['WOT']['ChildCost']) *
          noc;
      return cost;
    } else {
      let totalPrice = 0;
      const maxPassengersArray = activity.price.PT.map((obj) => [
        obj.maxPassengers,
        obj.price,
      ]);
      maxPassengersArray.sort((x, y) => x[0] - y[0]);
      let totalPassengers = noa + noc;
      const zerosArray = Array(maxPassengersArray.length).fill(0);

      while (totalPassengers > 0) {
        let flag = false;
        for (let i = 0; i < maxPassengersArray.length; i++) {
          if (parseInt(maxPassengersArray[i][0], 10) > totalPassengers) {
            zerosArray[i] += 1;
            flag = true;
            break;
          } else if (i === maxPassengersArray.length - 1) {
            zerosArray[i] += 1;
          }
        }
        if (flag) {
          totalPassengers = -1;
        } else {
          totalPassengers -= parseInt(
            maxPassengersArray[maxPassengersArray.length - 1][0],
            10,
          );
        }
      }

      zerosArray.forEach((count, i) => {
        totalPrice += count * parseInt(maxPassengersArray[i][1], 10);
      });

      totalPrice +=
        activity.price['WOT']['AdultCost'] * noa +
        activity.price['WOT']['ChildCost'] * noc;

      return totalPrice;
    }
  }
  return 0;
};
