// src/components/EditItinerary/ActivityItem.jsx

import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Field } from 'formik';
import CustomSelect from '../customSelect/CustomSelect';

const ActivityItem = ({
  dayIndex,
  activityIndex,
  values,
  setFieldValue,
  activityData,
  state,
  dispatch,
  currencies,
  country,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          <Typography fontWeight={700}>
            Activity: {activityIndex + 1} (Day {dayIndex + 1}: &nbsp;
            {values.days[dayIndex].date})
          </Typography>
          <Field
            className="custom-select"
            name={`days.${dayIndex}.${activityIndex}.activityId`}
            options={
              activityData?.filter(
                (x) =>
                  x.location.toLowerCase() === values.location.toLowerCase(),
              ) || []
            }
            fieldName={'activityName'}
            fieldValue={'_id'}
            component={CustomSelect}
            defaultValue={
              state.days?.[dayIndex]?.[activityIndex]?.activity?._id?.toString()
                ?.length > 0 &&
              activityData?.find(
                (x) =>
                  x._id.toString() ===
                  state.days?.[dayIndex]?.[
                    activityIndex
                  ]?.activity._id.toString(),
              )
            }
            placeholder="Select Activity"
            isMulti={false}
            custom={true}
            cb={(e) => {
              dispatch({
                type: 'CHANGE_SINGLE_DAY',
                payload: {
                  id: e,
                  index: dayIndex,
                  idx: activityIndex,
                  activityData,
                },
              });

              if (state.days[dayIndex][activityIndex].transportType) {
                dispatch({
                  type: 'CHANGE_TRANSFER',
                  payload: {
                    index: dayIndex,
                    idx: activityIndex,
                    transportType:
                      state.days[dayIndex][activityIndex].transportType,
                  },
                });
              }
            }}
          />
        </Grid>
        <Grid item sm={12} md={2}>
          <Typography fontWeight={700}>Transfer Option</Typography>
          <Field
            className="custom-select"
            name={`days.${dayIndex}.${activityIndex}.transportType`}
            fieldName={'name'}
            fieldValue={'type'}
            options={
              state.days?.[dayIndex]?.[activityIndex]?.activity?.priceTags || []
            }
            defaultValue={
              state.days?.[dayIndex]?.[activityIndex]?.activity
                .transportType && {
                name:
                  state.days?.[dayIndex]?.[activityIndex]?.activity
                    .transportType === 'PT'
                    ? 'Private Transfer'
                    : state.days?.[dayIndex]?.[activityIndex]?.activity
                          .transportType === 'ST'
                      ? 'Shared Transfer'
                      : 'Without Transfer',
                type: state.days?.[dayIndex]?.[activityIndex]?.activity
                  .transportType,
              }
            }
            component={CustomSelect}
            placeholder="Select Transfer Option"
            isMulti={false}
            custom={false}
            cb={(e) => {
              dispatch({
                type: 'CHANGE_TRANSFER',
                payload: {
                  index: dayIndex,
                  idx: activityIndex,
                  transportType: e,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={12} md={1}>
          <Typography fontWeight={700}>Adults</Typography>
          <Typography fontWeight={700}>
            {state.days[dayIndex][activityIndex].numberOfAdults}
          </Typography>
        </Grid>
        <Grid item sm={12} md={1}>
          <Typography fontWeight={700}>Children</Typography>
          <Typography fontWeight={700}>
            {state.days[dayIndex][activityIndex].numberOfChildren}
          </Typography>
        </Grid>
        <Grid item sm={12} md={1}>
          <Typography fontWeight={700}>Infants</Typography>
          <Typography fontWeight={700}>
            {state.days[dayIndex][activityIndex].numberOfInfants}
          </Typography>
        </Grid>
        <Grid item sm={12} md={2}>
          <Typography fontWeight={700}>Total Price</Typography>
          <Typography fontWeight={800} display="flex">
            {currencies[country][0].code}{' '}
            {parseFloat(
              state.days?.[dayIndex]?.[activityIndex]?.TotalCost || 0.0,
            ).toFixed(2) || 0.0}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivityItem;
