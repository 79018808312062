import React from 'react';
import {
  useForm,
  useFieldArray,
  Controller,
  useFormContext,
  FormProvider,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { compressAndUploadImages } from '../../../../helpers/imageUploadHelper';
import { useCreateHotelMutation } from '../../../../apis/hotelApis/hotelApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

// Define the validation schema with Zod
const festiveSeasonSchema = z
  .object({
    start: z.date({
      required_error: 'Festive start date is required',
      invalid_type_error: 'Invalid date',
    }),
    end: z.date({
      required_error: 'Festive end date is required',
      invalid_type_error: 'Invalid date',
    }),
    festive_price: z.number().nonnegative('Price must be a positive number'),
  })
  .refine((data) => data.end >= data.start, {
    message: 'End date must be after start date',
    path: ['end'],
  });

// Define the validation schema with Zod
const roomSchema = z.object({
  type: z.string().nonempty('Room type is required'),
  base_price: z
    .number()
    .min(1, 'Price must be at least 1')
    .nonnegative('Price must be a positive number'),
  breakfast_price: z
    .number()
    .min(1, 'Price must be at least 1')
    .nonnegative('Price must be a positive number'),
  lunch_price: z
    .number()
    .min(1, 'Price must be at least 1')
    .nonnegative('Price must be a positive number'),
  dinner_price: z
    .number()
    .min(1, 'Price must be at least 1')
    .nonnegative('Price must be a positive number'),
  weekend_price: z
    .number()
    .min(1, 'Price must be at least 1')
    .nonnegative('Price must be a positive number'),
  capacityAdult: z
    .number()
    .min(1, 'Adult capacity is required')
    .nonnegative('Adult capacity must be a positive number'),
  capacityChild: z
    .number()
    .nonnegative('Child capacity must be a non-negative number')
    .optional(),
  capacityInfant: z
    .number()
    .nonnegative('Infant capacity must be a non-negative number')
    .optional(),
  facility: z.string().optional(),
  images: z.array(z.instanceof(File)).optional(),
  festive_seasons: z
    .array(festiveSeasonSchema)
    .min(1, 'At least one festive season is required'),
});

const hotelSchema = z.object({
  hotelName: z.string().nonempty('Hotel Name is required'),
  description: z.string().nonempty('Description is required'),
  images: z.array(z.instanceof(File)).optional(),
  address: z.string().optional(),
  location: z.string().nonempty('Location is required'),
  country: z.string().nonempty('Country is required'),
  zipCode: z.string().optional(),
  phone: z.string().optional(),
  website: z.string().optional(),
  rating: z.number().min(0).max(5).optional(),
  amenities: z.string().optional(),
  rooms: z.array(roomSchema),
});

const HotelRoomfestivPrice = ({ roomIndex }) => {
  const {
    control,
    register,
    // formState: { errors },
  } = useFormContext();
  const {
    fields: festiveFields,
    append: appendFestive,
    remove: removeFestive,
  } = useFieldArray({
    control,
    name: `rooms.${roomIndex}.festive_seasons`,
  });

  return (
    <div className="py-4">
      <h5 className="mt-4 mb-2 font-semibold">Festive Seasons</h5>
      {festiveFields.map((festive, festiveIndex) => (
        <div key={festive.id} className="mb-2">
          <div className="flex items-center space-x-2">
            <div className="">
              <label className="block text-gray-700">Festive Start Date</label>
              <Controller
                control={control}
                name={`rooms.${roomIndex}.festive_seasons.${festiveIndex}.start`}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select start date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                )}
              />
              {/* {errors.rooms?.[roomIndex]?.festive_seasons?.[festiveIndex]?.start && (
                <p className="text-sm text-red-500">
                  {errors.rooms[roomIndex].festive_seasons[festiveIndex].start.message}
                </p>
              )} */}
            </div>

            <div className="flex-1">
              <label className="block text-gray-700">Festive End Date</label>
              <Controller
                control={control}
                name={`rooms.${roomIndex}.festive_seasons.${festiveIndex}.end`}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select end date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                )}
              />
              {/* {errors.rooms?.[roomIndex]?.festive_seasons?.[festiveIndex]?.end && (
                <p className="text-sm text-red-500">
                  {errors.rooms[roomIndex].festive_seasons[festiveIndex].end.message}
                </p>
              )} */}
            </div>
            <div className="flex-1">
              <label className="block text-gray-700">Festive Price</label>
              <input
                type="number"
                {...register(
                  `rooms.${roomIndex}.festive_seasons.${festiveIndex}.festive_price`,
                  {
                    valueAsNumber: true,
                  },
                )}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {/* {errors.rooms?.[roomIndex]?.festive_seasons?.[festiveIndex]
                ?.festive_price && (
                <p className="text-sm text-red-500">
                  {
                    errors.rooms?.[roomIndex]?.festive_seasons?.[festiveIndex]
                      ?.festive_price.message
                  }
                </p>
              )} */}
            </div>
            <button
              type="button"
              onClick={() => removeFestive(festiveIndex)}
              className="px-3 py-1 mt-2 text-white bg-red-500 rounded-md"
            >
              Remove Festive Season
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={() =>
          appendFestive({
            start: null,
            end: null,
          })
        }
        className="px-4 py-2 mt-2 text-white bg-blue-500 rounded-md"
      >
        Add Festive Season
      </button>
    </div>
  );
};

const CreateHotelForm = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(hotelSchema),
    defaultValues: {
      hotelName: '',
      description: '',
      images: [],
      address: '',
      location: '',
      country: '',
      zipCode: '',
      phone: '',
      website: '',
      rating: '',
      amenities: '',
      rooms: [
        {
          type: '',
          price: '',
          capacityAdult: '',
          capacityChild: '',
          capacityInfant: '',
          facility: '',
          images: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rooms',
  });

  const [createHotel] = useCreateHotelMutation();

  const handleFileChange = (e, fieldName) => {
    const files = Array.from(e.target.files);
    setValue(fieldName, files);
  };

  async function replaceImgWithUrls(blob, formObj, keyName) {
    const imageUrlArr = await compressAndUploadImages(blob, 'hotel');
    formObj[keyName] = imageUrlArr;
  }

  const onSubmit = async (data) => {
    try {
      await replaceImgWithUrls(data.images, data, 'images');

      const roomPromises = data.rooms.map(async (room) => {
        await replaceImgWithUrls(room.images, room, 'images');
      });

      await Promise.all(roomPromises);

      await createHotel({
        ...data,
        rooms: data.rooms.map((room) => ({
          ...room,
          price: {
            breakfast_price: room.breakfast_price || 0,
            lunch_price: room.lunch_price || 0,
            dinner_price: room.dinner_price || 0,
            base_price: room.base_price || 0,
            weekend_price: room.weekend_price || 0,
            festive_seasons: room.festive_seasons,
          },
        })),
      })
        .unwrap()
        .then((res) => {
          toast.success(`Hotel Created Successfully`, TOAST_CONFIG);
          reset();
          navigate('/dashboard/hotels');
        })
        .catch((err) => {
          toast.error(`Failed! Could not create hotel`, TOAST_CONFIG);

          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider register={register} control={control}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-6 mx-auto bg-white rounded-lg shadow-md"
      >
        <h2 className="mb-6 text-2xl font-bold text-center">Create Hotel</h2>

        <div className="mb-4">
          <label className="block text-gray-700">Hotel Name</label>
          <input
            {...register('hotelName')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.hotelName && (
            <p className="text-sm text-red-500">{errors.hotelName.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            {...register('description')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.description && (
            <p className="text-sm text-red-500">{errors.description.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Images</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => handleFileChange(e, 'images')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.images && (
            <p className="text-sm text-red-500">{errors.images.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Address</label>
          <input
            {...register('address')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.address && (
            <p className="text-sm text-red-500">{errors.address.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Location</label>
          <select
            {...register('location')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          >
            <option value="" disabled>
              Select Location
            </option>
            <option value="Dubai">Dubai</option>
            <option value="Singapore">Singapore</option>
            <option value="Bali">Bali</option>
            <option value="Thailand">Thailand</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Baku">Baku</option>
            <option value="Manali">Manali</option>
            <option value="Jaisalmer">Jaisalmer</option>
          </select>
          {errors.location && (
            <p className="text-sm text-red-500">{errors.location.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Country</label>
          <select
            {...register('country')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          >
            <option value="" disabled>
              Select Country
            </option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="Republic of Singapore">Republic of Singapore</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Thailand">Kingdom of Thailand</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="India">India</option>
            <option value="India">India</option>
          </select>
          {errors.country && (
            <p className="text-sm text-red-500">{errors.country.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Zip Code</label>
          <input
            {...register('zipCode')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.zipCode && (
            <p className="text-sm text-red-500">{errors.zipCode.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Phone</label>
          <input
            {...register('phone')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.phone && (
            <p className="text-sm text-red-500">{errors.phone.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Website</label>
          <input
            {...register('website')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.website && (
            <p className="text-sm text-red-500">{errors.website.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Rating</label>
          <input
            type="number"
            step="0.1"
            {...register('rating', { valueAsNumber: true })}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.rating && (
            <p className="text-sm text-red-500">{errors.rating.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">
            Amenities (comma-separated)
          </label>
          <input
            {...register('amenities')}
            className="w-full p-2 mt-1 border border-gray-300 rounded-md"
          />
          {errors.amenities && (
            <p className="text-sm text-red-500">{errors.amenities.message}</p>
          )}
        </div>

        <h3 className="mt-6 mb-4 text-xl font-bold">Rooms</h3>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className="p-4 mb-4 border border-gray-200 rounded-lg"
          >
            <h4 className="mb-2 font-bold">Room {index + 1}</h4>

            <div className="mb-2">
              <label className="block text-gray-700">Type</label>
              <input
                {...register(`rooms.${index}.type`)}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.type && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].type.message}
                </p>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700">Price</label>
              <input
                type="number"
                {...register(`rooms.${index}.base_price`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.base_price && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].base_price.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-gray-700">Weekend Price</label>
              <input
                type="number"
                {...register(`rooms.${index}.weekend_price`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.weekend_price && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].weekend_price.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-gray-700">Breakfast Price</label>
              <input
                type="number"
                {...register(`rooms.${index}.breakfast_price`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.breakfast_price && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].breakfast_price.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-gray-700">Lunch Price</label>
              <input
                type="number"
                {...register(`rooms.${index}.lunch_price`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.lunch_price && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].lunch_price.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-gray-700">Dinner Price</label>
              <input
                type="number"
                {...register(`rooms.${index}.dinner_price`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.dinner_price && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].dinner_price.message}
                </p>
              )}
            </div>
            <HotelRoomfestivPrice roomIndex={index} />
            {/* <div className="mb-2">
            <label className="block text-gray-700">Festive Start Date</label>
            <Controller
              control={control}
              name={`rooms.${index}.festive_start`}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Festive End Date</label>
            <Controller
              control={control}
              name={`rooms.${index}.festive_end`}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </div> */}

            <div className="mb-2">
              <label className="block text-gray-700">Capacity (Adult)</label>
              <input
                type="number"
                {...register(`rooms.${index}.capacityAdult`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.capacityAdult && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].capacityAdult.message}
                </p>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700">Capacity (Child)</label>
              <input
                type="number"
                {...register(`rooms.${index}.capacityChild`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.capacityChild && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].capacityChild.message}
                </p>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700">Capacity (Infant)</label>
              <input
                type="number"
                {...register(`rooms.${index}.capacityInfant`, {
                  valueAsNumber: true,
                })}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.capacityInfant && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].capacityInfant.message}
                </p>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700">
                Facility (comma-separated)
              </label>
              <input
                {...register(`rooms.${index}.facility`)}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.facility && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].facility.message}
                </p>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700">Images</label>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={(e) => handleFileChange(e, `rooms.${index}.images`)}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
              {errors.rooms?.[index]?.images && (
                <p className="text-sm text-red-500">
                  {errors.rooms[index].images.message}
                </p>
              )}
            </div>

            <button
              type="button"
              onClick={() => remove(index)}
              className="px-4 py-2 mt-2 text-white bg-red-500 rounded-md"
            >
              Remove Room
            </button>
          </div>
        ))}

        <button
          type="button"
          onClick={() =>
            append({
              type: '',
              price: '',
              capacityAdult: '',
              capacityChild: '',
              capacityInfant: '',
              facility: '',
              images: [],
            })
          }
          className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md"
        >
          Add Room
        </button>

        <button
          type="submit"
          className="px-4 py-2 mt-6 text-white bg-green-500 rounded-md"
        >
          Create Hotel
        </button>
      </form>
    </FormProvider>
  );
};

export default CreateHotelForm;
