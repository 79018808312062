import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetActivityByIdQuery } from '../../../../apis/activityApis/activityApi';
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import ModuleHeader from '../../../../components/moduleHeader/ModuleHeader';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../../../app/store/reducers/currencySlice';

const ViewActivity = () => {
  const { id } = useParams();
  const currencies = useSelector(getAllCurrencies);

  const { data: activity, isLoading, isError } = useGetActivityByIdQuery(id);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching activity</Typography>;
  }

  if (!activity) {
    return <Typography>No activity data available</Typography>;
  }

  const transferLabel =
    activity.type === 'Meal'
      ? 'Meal Transfer Prices'
      : activity.type === 'Entrance'
        ? 'Entrance Ticket Prices'
        : 'Without Transfer';

  const currencyCode = currencies[activity.country]?.[0]?.code || 'N/A'; 

  return (
    <>
      {activity && (
        <>
          <ModuleHeader
            headerTitle={activity.activityName}
            headerImg={activity.images[0]}
          />
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      Activity Type
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {activity.type}
                    </Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      Description
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {activity?.description}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      Tour Inclusions
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {activity?.tourInclusions}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      What to Expect
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {activity?.WhattoExpect}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h5" fontWeight={700} gutterBottom>
                    Location:
                  </Typography>
                  <Typography>{activity.location}</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" fontWeight={700} gutterBottom>
                    Country:
                  </Typography>
                  <Typography>{activity.country}</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" fontWeight={700} gutterBottom>
                    Price:
                  </Typography>

                  <Typography variant="h6">{transferLabel}: </Typography>
                  <Typography variant="body1">
                    Adult Price: {currencyCode}{' '}
                    {activity?.price?.WOT?.AdultCost || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    Child Price: {currencyCode}{' '}
                    {activity?.price?.WOT?.ChildCost || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    Infant Price: {currencyCode}{' '}
                    {activity?.price?.WOT?.InfantCost || 'N/A'}
                  </Typography>
                  <Typography variant="h6">Sharing Transfer: </Typography>
                  <Typography variant="body1">
                    Adult Price: {currencyCode}{' '}
                    {activity?.price?.ST?.AdultCost || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    Child Price: {currencyCode}{' '}
                    {activity?.price?.ST?.ChildCost || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    Infant Price: {currencyCode}{' '}
                    {activity?.price?.ST?.InfantCost || 'N/A'}
                  </Typography>
                  <>
                    <Typography variant="h6">
                      Private Transfer Price:
                    </Typography>
                    {activity.price.PT?.length > 0 ? (
                      activity.price.PT.map((transport) => (
                        <Box key={transport._id} mb={1}>
                          <Typography>
                            Passengers: {transport.minPassengers} to{' '}
                            {transport.maxPassengers}
                          </Typography>
                          <Typography>
                            Price: {currencyCode} {transport.price || 'N/A'}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography>
                        No private transfer prices available
                      </Typography>
                    )}
                  </>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ViewActivity;
