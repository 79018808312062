import React, { useState } from 'react';
import { useFormik, FormikProvider, FieldArray, Field } from 'formik';
import _ from 'lodash';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { uploadImages } from '../../../../helpers/imageUploadHelper';
import { useCreateActivityMutation } from '../../../../apis/activityApis/activityApi';
import { toast } from 'react-toastify';
import Select from '@mui/material/Select';
import {
  useGetAllLocationsQuery,
  useGetAllCountriesQuery,
} from '../../../../apis/locationsApi/locationsApi';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const CreateActivities = () => {
  const [createActivity] = useCreateActivityMutation();
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const { data: allCountries, isLoading: countriesLoading } =
    useGetAllCountriesQuery();
  const { data: allLocations, isLoading: locationsLoading } =
    useGetAllLocationsQuery({ keyword: '' });

  const INITIAL_VALUES = {
    type: '',
    activityName: '',
    description: '',
    tourInclusions: '',
    WhattoExpect: '',
    location: '',
    locationName: '',
    country: '',
    source: '',
    destination: '',
    duration: 0,
    images: [],
    rating: '',
    price: {
      WOT: {
        AdultCost: 0,
        ChildCost: 0,
        InfantCost: 0,
      },
      ST: {
        AdultCost: 0,
        ChildCost: 0,
        InfantCost: 0,
      },
      PT: [
        {
          minPassengers: 0,
          maxPassengers: 1,
          price: 0,
        },
      ],
      meal: {
        mealType: '',
        adultCost: 0,
        childCost: 0,
        infantCost: 0,
        images: [],
      },
    },
  };

  async function replaceImgWithUrls(formObj) {
    const activityImageUrlArr = await uploadImages(
      formObj.images,
      'activities',
    );
    formObj.images = activityImageUrlArr;
  }
  const submitHandler = async (values, actions) => {
    const reqBody = _.cloneDeep(values);
    const tourInclusions = values.tourInclusions
      .trim()
      .replace(/(\r\n|\n|\r)/gm, '')
      .split(',');
    const WhattoExpect = values.WhattoExpect.trim()
      .replace(/(\r\n|\n|\r)/gm, '')
      .split(',');
    console.log(values);
    await replaceImgWithUrls(reqBody)
      .then(() => {
        createActivity(reqBody)
          .unwrap()
          .then((res) => {
            toast.success(
              `Activity: ${values.activityName} Created Successfully`,
              TOAST_CONFIG,
            );
            actions.resetForm();
          })
          .catch((err) => {
            actions.resetForm();
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
  });

  const handleHotelImageUpload = (e) => {
    const fileList = e.target.files;
    formik.setFieldValue('images', [...fileList]);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    // validationSchema: loginSchema,
  });

  return (
    <Paper elevation={6} sx={{ padding: '1rem' }}>
      <Typography variant="h4" fontWeight={700}>
        Create Activities
      </Typography>
      <Divider />
      <br />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Activity Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  value={formik.values.type}
                  label="Activity Type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'Transfer'}>Transfer</MenuItem>
                  <MenuItem value={'Fun'}>Fun</MenuItem>
                  <MenuItem value={'Meal'}>Meal</MenuItem>
                  <MenuItem value={'Entrance'}>Entrance Tickets</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formik.values.type && formik.values.type === 'Fun' && (
              <>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="activityName"
                    label="Activity Name"
                    name="activityName"
                    type="text"
                    placeholder="Enter Activity Name"
                    value={formik.values.activityName}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.activityName && formik.touched.activityName
                    }
                    helperText={
                      formik.errors.activityName &&
                      formik.touched.activityName &&
                      formik.errors.activityName
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    type="text"
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.description && formik.touched.description
                    }
                    helperText={
                      formik.errors.description &&
                      formik.touched.description &&
                      formik.errors.description
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="tourInclusions"
                    label="Tour Inclusions"
                    name="tourInclusions"
                    type="text"
                    placeholder="Enter TourInclusions (Comma Separated)"
                    value={formik.values.tourInclusions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions
                    }
                    helperText={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions &&
                      formik.errors.tourInclusions
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="WhattoExpect"
                    label="What to Expect"
                    name="WhattoExpect"
                    type="text"
                    placeholder="Enter WhattoExpect (Comma Separated)"
                    value={formik.values.WhattoExpect}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.WhattoExpect && formik.touched.WhattoExpect
                    }
                    helperText={
                      formik.errors.WhattoExpect &&
                      formik.touched.WhattoExpect &&
                      formik.errors.WhattoExpect
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    label="Country"
                    name="country"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.setFieldValue('country', e.target.value);
                      formik.setFieldValue('visaStatus', false);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  >
                    {allCountries?.data?.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    label="City Name"
                    name="location"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                  >
                    {allLocations?.data?.map(
                      (location) =>
                        location.country === formik.values.country && (
                          <MenuItem
                            key={location._id}
                            value={location.locationName || ''}
                          >
                            {location.locationName}
                          </MenuItem>
                        ),
                    )}
                  </TextField>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="duration"
                    label="Duration (Minutes)"
                    name="duration"
                    type="text"
                    placeholder="Enter Duration in Minutes"
                    value={formik.values.duration}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.duration && formik.touched.duration}
                    helperText={
                      formik.errors.duration &&
                      formik.touched.duration &&
                      formik.errors.duration
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                {/* <Grid item sm={12} md={4}>
                  <TextField
                    id="rating"
                    label="Rating"
                    name="rating"
                    type="text"
                    placeholder="Enter Rating"
                    value={formik.values.rating}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.rating && formik.touched.rating}
                    helperText={
                      formik.errors.rating && formik.touched.rating && formik.errors.rating
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid> */}
                <Grid item sm={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={'bold'}>
                      {formik.values.type === 'Fun' && <> Without </>} Transfer
                      Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTAdultCost"
                    label="Without Transfers Adult Price"
                    name="price.WOT.AdultCost"
                    type="number"
                    placeholder="Enter Without Transfers Adult Price"
                    value={formik.values.price?.WOT?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost &&
                      formik.errors.price.WOT.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTChildCost"
                    label="Without Transfers Child Price"
                    name="price.WOT.ChildCost"
                    type="number"
                    placeholder="Enter Without Transfers Child Price"
                    value={formik.values.price?.WOT?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost &&
                      formik.errors.price.WOT.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTInfantCost"
                    label="Without Transfers Infant Price"
                    name="price.WOT.InfantCost"
                    type="number"
                    placeholder="Enter Without Transfers Infant Price"
                    value={formik.values.price?.WOT?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost &&
                      formik.errors.price.WOT.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={'bold'}>
                      Shared Transfer Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STAdultCost"
                    label="Share Transfers Adult Price"
                    name="price.ST.AdultCost"
                    type="number"
                    placeholder="Enter Share Transfers Adult Price"
                    value={formik.values.price?.ST?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.AdultCost &&
                      formik.touched.price?.ST?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.ST?.AdultCost &&
                      formik.touched.price?.ST?.AdultCost &&
                      formik.errors.price.ST.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STChildCost"
                    label="Share Transfers Child Price"
                    name="price.ST.ChildCost"
                    type="number"
                    placeholder="Enter Share Transfers Child Price"
                    value={formik.values.price?.ST?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.ChildCost &&
                      formik.touched.price?.ST?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.ST?.ChildCost &&
                      formik.touched.price?.ST?.ChildCost &&
                      formik.errors.price.ST.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STInfantCost"
                    label="Share Transfers Infant Price"
                    name="price.ST.InfantCost"
                    type="number"
                    placeholder="Enter Share Transfers Infant Price"
                    value={formik.values.price?.ST?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.InfantCost &&
                      formik.touched.price?.ST?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.ST?.InfantCost &&
                      formik.touched.price?.ST?.InfantCost &&
                      formik.errors.price.ST.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <FieldArray
                    name="price.PT"
                    render={(arrayHelpers) => (
                      <>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          marginY={1}
                        >
                          <Typography variant="h5" fontWeight={'bold'}>
                            Private Transfer
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                minPassengers: 0,
                                maxPassengers: 1,
                                price: 0,
                              })
                            }
                          >
                            Add Range
                          </Button>
                        </Box>
                        <Divider />

                        {formik.values.price.PT.map((_, idx) => (
                          <Grid container gap={3} key={idx} marginY={2}>
                            <Grid item sm={12} md={3} alignItems={'center'}>
                              <TextField
                                label="Enter Minimum Passengers"
                                name={`price.PT[${idx}].minPassengers`}
                                type="number"
                                placeholder="Enter Minimum passenger count"
                                value={
                                  formik.values.price?.PT[idx]?.minPassengers
                                }
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Grid item sm={12} md={3}>
                              <TextField
                                label="Enter Maximum Passengers"
                                name={`price.PT[${idx}].maxPassengers`}
                                type="number"
                                placeholder="Enter maximum passenger count"
                                value={
                                  formik.values.price?.PT[idx]?.maxPassengers
                                }
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Grid item sm={12} md={3}>
                              <TextField
                                label="Enter Price"
                                name={`price.PT[${idx}].price`}
                                type="number"
                                placeholder="Enter Price"
                                value={formik.values.price?.PT[idx]?.price}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              -
                            </Button>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: '1rem' }}
                  >
                    Upload Image
                    <input
                      required
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleHotelImageUpload(e)}
                    />
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    {formik.values.images.length > 0 &&
                      formik.values.images.map((img, idx) => (
                        <Box key={idx} width={'20rem'}>
                          <img
                            src={
                              typeof img === String
                                ? img
                                : URL.createObjectURL(img)
                            }
                            alt={img.name}
                            width={'100%'}
                            style={{ borderRadius: '10px' }}
                          />
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </>
            )}
            {formik.values.type && formik.values.type === 'Entrance' && (
              <>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="activityName"
                    label="Activity Name"
                    name="activityName"
                    type="text"
                    placeholder="Enter Activity Name"
                    value={formik.values.activityName}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.activityName && formik.touched.activityName
                    }
                    helperText={
                      formik.errors.activityName &&
                      formik.touched.activityName &&
                      formik.errors.activityName
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    type="text"
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.description && formik.touched.description
                    }
                    helperText={
                      formik.errors.description &&
                      formik.touched.description &&
                      formik.errors.description
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="tourInclusions"
                    label="Tour Inclusions"
                    name="tourInclusions"
                    type="text"
                    placeholder="Enter TourInclusions (Comma Separated)"
                    value={formik.values.tourInclusions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions
                    }
                    helperText={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions &&
                      formik.errors.tourInclusions
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="WhattoExpect"
                    label="What to Expect"
                    name="WhattoExpect"
                    type="text"
                    placeholder="Enter WhattoExpect (Comma Separated)"
                    value={formik.values.WhattoExpect}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.WhattoExpect && formik.touched.WhattoExpect
                    }
                    helperText={
                      formik.errors.WhattoExpect &&
                      formik.touched.WhattoExpect &&
                      formik.errors.WhattoExpect
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    label="Country"
                    name="country"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.setFieldValue('country', e.target.value);
                      formik.setFieldValue('visaStatus', false);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  >
                    {allCountries?.data?.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    label="City Name"
                    name="location"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                  >
                    {allLocations?.data?.map(
                      (location) =>
                        location.country === formik.values.country && (
                          <MenuItem
                            key={location._id}
                            value={location.locationName || ''}
                          >
                            {location.locationName}
                          </MenuItem>
                        ),
                    )}
                  </TextField>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="duration"
                    label="Duration (Minutes)"
                    name="duration"
                    type="text"
                    placeholder="Enter Duration in Minutes"
                    value={formik.values.duration}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.duration && formik.touched.duration}
                    helperText={
                      formik.errors.duration &&
                      formik.touched.duration &&
                      formik.errors.duration
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={'bold'}>
                      {formik.values.type === 'Entrance' && <> Entrance </>}{' '}
                      Ticket Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTAdultCost"
                    label="Entrance Transfers Adult Price"
                    name="price.WOT.AdultCost"
                    type="number"
                    placeholder="Enter Entrance Transfers Adult Price"
                    value={formik.values.price?.WOT?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost &&
                      formik.errors.price.WOT.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTChildCost"
                    label="Entrance Transfers Child Price"
                    name="price.WOT.ChildCost"
                    type="number"
                    placeholder="Enter Entrance Transfers Child Price"
                    value={formik.values.price?.WOT?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost &&
                      formik.errors.price.WOT.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTInfantCost"
                    label="Entrance Transfers Infant Price"
                    name="price.WOT.InfantCost"
                    type="number"
                    placeholder="Enter Entrance Transfers Infant Price"
                    value={formik.values.price?.WOT?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost &&
                      formik.errors.price.WOT.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: '1rem' }}
                  >
                    Upload Image
                    <input
                      required
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleHotelImageUpload(e)}
                    />
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    {formik.values.images.length > 0 &&
                      formik.values.images.map((img, idx) => (
                        <Box key={idx} width={'20rem'}>
                          <img
                            src={
                              typeof img === String
                                ? img
                                : URL.createObjectURL(img)
                            }
                            alt={img.name}
                            width={'100%'}
                            style={{ borderRadius: '10px' }}
                          />
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </>
            )}
            {formik.values.type && formik.values.type === 'Transfer' && (
              <>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="activityName"
                    label="Activity Name"
                    name="activityName"
                    type="text"
                    placeholder="Enter Activity Name"
                    value={formik.values.activityName}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.activityName && formik.touched.activityName
                    }
                    helperText={
                      formik.errors.activityName &&
                      formik.touched.activityName &&
                      formik.errors.activityName
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="source"
                    label="Source"
                    name="source"
                    type="text"
                    placeholder="Enter source"
                    value={formik.values.source}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.source && formik.touched.source}
                    helperText={
                      formik.errors.source &&
                      formik.touched.source &&
                      formik.errors.source
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="destination"
                    label="Destination"
                    name="destination"
                    type="text"
                    placeholder="Enter destination"
                    value={formik.values.destination}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.destination && formik.touched.destination
                    }
                    helperText={
                      formik.errors.destination &&
                      formik.touched.destination &&
                      formik.errors.destination
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    label="Country"
                    name="country"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.setFieldValue('country', e.target.value);
                      formik.setFieldValue('visaStatus', false);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  >
                    {allCountries?.data?.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    label="City Name"
                    name="location"
                    select
                    fullWidth
                    required
                    className="mb-3"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                  >
                    {allLocations?.data?.map(
                      (location) =>
                        location.country === formik.values.country && (
                          <MenuItem
                            key={location._id}
                            value={location.locationName || ''}
                          >
                            {location.locationName}
                          </MenuItem>
                        ),
                    )}
                  </TextField>
                </Grid>

                {/* <Grid item sm={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={'bold'}>
                      {formik.values.type === 'Fun' && <> Without </>} Transfer
                      Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTAdultCost"
                    label="Without Transfers Adult Price"
                    name="price.WOT.AdultCost"
                    type="number"
                    placeholder="Enter Without Transfers Adult Price"
                    value={formik.values.price?.WOT?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost &&
                      formik.errors.price.WOT.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTChildCost"
                    label="Without Transfers Child Price"
                    name="price.WOT.ChildCost"
                    type="number"
                    placeholder="Enter Without Transfers Child Price"
                    value={formik.values.price?.WOT?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost &&
                      formik.errors.price.WOT.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTInfantCost"
                    label="Without Transfers Infant Price"
                    name="price.WOT.InfantCost"
                    type="number"
                    placeholder="Enter Without Transfers Infant Price"
                    value={formik.values.price?.WOT?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost &&
                      formik.errors.price.WOT.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid> */}
                {/* <Grid item sm={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={"bold"}>
                      Shared Transfer Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STAdultCost"
                    label="share Transfers Adult Price"
                    name="price.ST.AdultCost"
                    type="number"
                    placeholder="Enter share Transfers Adult Price"
                    value={formik.values.price?.ST?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.AdultCost &&
                      formik.touched.price?.ST?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.ST?.AdultCost &&
                      formik.touched.price?.ST?.AdultCost &&
                      formik.errors.price.ST.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STChildCost"
                    label="share Transfers Child Price"
                    name="price.ST.ChildCost"
                    type="number"
                    placeholder="Enter share Transfers Child Price"
                    value={formik.values.price?.ST?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.ChildCost &&
                      formik.touched.price?.ST?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.ST?.ChildCost &&
                      formik.touched.price?.ST?.ChildCost &&
                      formik.errors.price.ST.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="STInfantCost"
                    label="share Transfers Infant Price"
                    name="price.ST.InfantCost"
                    type="number"
                    placeholder="Enter share Transfers Infant Price"
                    value={formik.values.price?.ST?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.ST?.InfantCost &&
                      formik.touched.price?.ST?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.ST?.InfantCost &&
                      formik.touched.price?.ST?.InfantCost &&
                      formik.errors.price.ST.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid> */}
                <Grid item sm={12}>
                  <FieldArray
                    name="price.PT"
                    render={(arrayHelpers) => (
                      <>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          marginY={1}
                        >
                          <Typography variant="h5" fontWeight={'bold'}>
                            Private Transfer
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                minPassengers: 0,
                                maxPassengers: 1,
                                price: 0,
                              })
                            }
                          >
                            Add Range
                          </Button>
                        </Box>
                        <Divider />

                        {formik.values.price.PT.map((_, idx) => (
                          <Grid container gap={3} key={idx} marginY={2}>
                            <Grid item sm={12} md={3} alignItems={'center'}>
                              <TextField
                                label="Private Transfers Min Passenger"
                                name={`price.PT[${idx}].minPassengers`}
                                type="number"
                                placeholder="Enter Minimum passenger count"
                                value={
                                  formik.values.price?.PT[idx]?.minPassengers
                                }
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Grid item sm={12} md={3}>
                              <TextField
                                label="Private Transfers Child Price"
                                name={`price.PT[${idx}].maxPassengers`}
                                type="number"
                                placeholder="Enter maximum passenger count"
                                value={
                                  formik.values.price?.PT[idx]?.maxPassengers
                                }
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Grid item sm={12} md={3}>
                              <TextField
                                label="Private Transfers Infant Price"
                                name={`price.PT[${idx}].price`}
                                type="number"
                                placeholder="Enter Private Transfers Infant Price"
                                value={formik.values.price?.PT[idx]?.price}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                className="mb-3"
                              />
                            </Grid>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </>
            )}
            {formik.values.type && formik.values.type === 'Meal' && (
              <>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="activityName"
                    label="Activity Name"
                    name="activityName"
                    type="text"
                    placeholder="Enter Activity Name"
                    value={formik.values.activityName}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.activityName && formik.touched.activityName
                    }
                    helperText={
                      formik.errors.activityName &&
                      formik.touched.activityName &&
                      formik.errors.activityName
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    type="text"
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.description && formik.touched.description
                    }
                    helperText={
                      formik.errors.description &&
                      formik.touched.description &&
                      formik.errors.description
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="tourInclusions"
                    label="Tour Inclusions"
                    name="tourInclusions"
                    type="text"
                    placeholder="Enter TourInclusions (Comma Separated)"
                    value={formik.values.tourInclusions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions
                    }
                    helperText={
                      formik.errors.tourInclusions &&
                      formik.touched.tourInclusions &&
                      formik.errors.tourInclusions
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <Field
                    as={TextField}
                    id="WhattoExpect"
                    label="What to Expect"
                    name="WhattoExpect"
                    type="text"
                    placeholder="Enter WhattoExpect (Comma Separated)"
                    value={formik.values.WhattoExpect}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.errors.WhattoExpect && formik.touched.WhattoExpect
                    }
                    helperText={
                      formik.errors.WhattoExpect &&
                      formik.touched.WhattoExpect &&
                      formik.errors.WhattoExpect
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    select
                    label="Location"
                    name="location"
                    type="text"
                    fullWidth
                    className="mb-3"
                    value={formik.values.location}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    required
                  ></TextField>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Field
                    as={TextField}
                    select
                    label="Country Name"
                    name="country"
                    type="text"
                    fullWidth
                    className="mb-3"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    required
                  ></Field>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="duration"
                    label="Duration (Minutes)"
                    name="duration"
                    type="text"
                    placeholder="Enter Duration in Minutes"
                    value={formik.values.duration}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.duration && formik.touched.duration}
                    helperText={
                      formik.errors.duration &&
                      formik.touched.duration &&
                      formik.errors.duration
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginY={1}
                  >
                    <Typography variant="h5" fontWeight={'bold'}>
                      {formik.values.type === 'Meal' && <> Meal </>} Transfer
                      Prices
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTAdultCost"
                    label="Meal Transfers Adult Price"
                    name="price.WOT.AdultCost"
                    type="number"
                    placeholder="Enter Meal Transfers Adult Price"
                    value={formik.values.price?.WOT?.AdultCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.AdultCost &&
                      formik.touched.price?.WOT?.AdultCost &&
                      formik.errors.price.WOT.AdultCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTChildCost"
                    label="Meal Transfers Child Price"
                    name="price.WOT.ChildCost"
                    type="number"
                    placeholder="Enter Meal Transfers Child Price"
                    value={formik.values.price?.WOT?.ChildCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.ChildCost &&
                      formik.touched.price?.WOT?.ChildCost &&
                      formik.errors.price.WOT.ChildCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    id="WOTInfantCost"
                    label="Meal Transfers Infant Price"
                    name="price.WOT.InfantCost"
                    type="number"
                    placeholder="Enter Meal Transfers Infant Price"
                    value={formik.values.price?.WOT?.InfantCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost
                    }
                    helperText={
                      formik.errors.price?.WOT?.InfantCost &&
                      formik.touched.price?.WOT?.InfantCost &&
                      formik.errors.price.WOT.InfantCost
                    }
                    variant="outlined"
                    fullWidth
                    className="mb-3"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: '1rem' }}
                  >
                    Upload Image
                    <input
                      required
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleHotelImageUpload(e)}
                    />
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    {formik.values.images.length > 0 &&
                      formik.values.images.map((img, idx) => (
                        <Box key={idx} width={'20rem'}>
                          <img
                            src={
                              typeof img === String
                                ? img
                                : URL.createObjectURL(img)
                            }
                            alt={img.name}
                            width={'100%'}
                            style={{ borderRadius: '10px' }}
                          />
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Divider sx={{ marginY: '0.7rem' }} />
          <Button
            type="submit"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default CreateActivities;
